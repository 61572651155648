import React from "react";
import WheelSketch from "../components/WheelSketch";
const Wheel = () => {
  return (
    <>
      <WheelSketch />
    </>
  );
};

export default Wheel;
